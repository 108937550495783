import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TwoFactorFaqDrawer from "./TwoFactorFaqDrawer";

const TwoFactorFaqButton = () => {
  const [openFaqDrawer, setOpenFaqDrawer] = useState(false);
  return <>
    <Button
      fullWidth
      color="disabled"
      variant="outlined"
      data-testid="two-factor-faq-button"
      onClick={() => setOpenFaqDrawer(true)}
    >
      <Stack
        spacing={1}
        direction={"row"}
        sx={{ py: 1, width: "100%" }}
      >
        <HelpOutlineIcon color="disabled"/>
        <Typography
          align="left"
          flexGrow={1}
          sx={{ textTransform: "none" }}
        >
          Precisa de ajuda?
        </Typography>
        <ChevronRightIcon color="disabled"/>
      </Stack>
    </Button>
    <TwoFactorFaqDrawer
      open={openFaqDrawer}
      onClose={() => setOpenFaqDrawer(false)}
    />
  </>
}

export default TwoFactorFaqButton;