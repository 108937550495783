import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { ShadowTheme } from "../../ShadowTemplate";
import { hot } from "react-hot-loader";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import TwoFactorFaqButton from "./TwoFactorFaqButton";
import MandatoryTwoFactorBanner from "../security/MandatoryTwoFactorBanner";
import TwoFactorTypeSelect from "./TwoFactorTypeSelect";
import TwoFactorConfigDialog from "./TwoFactorConfigDialog";

const TwoFactorConfigShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <TwoFactorConfig {...props}/>
  </ShadowTheme>
}

const TwoFactorConfig = (props) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [openConfigDialog, setOpenConfigDialog] = useState(false);

  const title = props.mandatory ? 'Atenção! Necessária configuração do Duplo Fator de Autenticação (2FA)'
    : 'Duplo Fator de Autenticação (2FA)';

  return <>
    <Card sx={{ mx: smallScreen ? "1rem" : "18rem", mt: props.mandatory ? 12 : 3, mb: 6, pb: 1 }}>
      <CardHeader title={title} sx={{ mb: -4 }}/>
      <CardContent>
        <MandatoryTwoFactorBanner theme={theme} active={false}/>
        { props.mandatory && <Typography mt={2} color={"GrayText"}>
            Para reforçar a segurança do sistema, agora é obrigatória a ativação do 2FA (Autenticador de Dois Fatores) na sua conta.
          </Typography>
        }
        <Typography mt={1} mb={2} variant={ smallScreen ? "body1" : "h6" }>
          Escolha uma das opções para configurar:
        </Typography>
        <TwoFactorTypeSelect smallScreen={smallScreen}/>
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end", px: 2 }}>
        <Button
          variant="contained"
          onClick={() => setOpenConfigDialog(true)}
        >
          CONFIGURAR
        </Button>
      </CardActions>
      <Box m={2}>
        <TwoFactorFaqButton/>
      </Box>
    </Card>
    <TwoFactorConfigDialog
      open={openConfigDialog}
      onClose={() => setOpenConfigDialog(false)}
      secret={props.secret}
      qrCode={props.qrCode}
      csrfToken={props.csrfToken}
      smallScreen={smallScreen}
      mandatory={props.mandatory}
    />
  </>
}

export default hot(module)(TwoFactorConfigShadow);